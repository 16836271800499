<cc-form-container>
  <div form-buttons class="flex flex-row" style="margin-top: 10px">
    @if (!(selected$ | async)?.contractName) {
      <cc-action-button
        displayText="{{
          'limit-reports.button.ASSIGN_LIMIT_REPORT_TO_CONTRACT' | transloco
        }}"
        (onClick)="assignToContract()"
        *ngxPermissionsOnly="[permissions.limitReportPowerAssignToContract]"
      ></cc-action-button>
    }

    <cc-action-button
      displayText="{{ 'limit-reports.tab.LIMIT_REPORTS_OVERVIEW' | transloco }}"
      (onClick)="overview()"
      *ngxPermissionsOnly="[
        permissions.limitReportPowerEditor,
        permissions.limitReportPowerAdmin
      ]"
    ></cc-action-button>

    <cc-edit-action-button
      (onClick)="edit()"
      *ngxPermissionsOnly="[
        permissions.limitReportPowerEditor,
        permissions.limitReportPowerAdmin
      ]"
      >
    </cc-edit-action-button>
  </div>
  <form
    form-content
    [formGroup]="formGroup"
    [patchFormGroupValues]="selected$ | async"
    class="flex flex-col p-3"
    >
    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{
          'limit-reports.fields.REPORT_NAME' | transloco
        }}</mat-label>
        <input matInput formControlName="name" readonly />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'limit-reports.fields.NAME' | transloco }}</mat-label>
        <input matInput formControlName="customerName" readonly />
      </mat-form-field>
    </div>

    <div formContainerFormRow>
      <mat-form-field>
        <mat-label>{{ 'limit-reports.fields.STATE' | transloco }}</mat-label>
        <input
          matInput
          value="{{
            'limit-reports.common.limit-report-state.' +
              formGroup.controls.state.value | transloco
          }}"
          readonly
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{
            'limit-reports.fields.TIME_SERIES_IDENTIFIER' | transloco
          }}</mat-label>
          <input matInput formControlName="timeSeriesIdentifier" readonly />
        </mat-form-field>
      </div>

      <div formContainerFormRow>
        <mat-form-field>
          <mat-label>{{
            'limit-reports.fields.DEFAULT_CHART_START_DATE' | transloco
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="pickerDefaultChartStartDate"
            formControlName="defaultChartStartDate"
            readonly
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDefaultChartStartDate"
              style="visibility: hidden"
              >
            </mat-datepicker-toggle>
            <mat-datepicker #pickerDefaultChartStartDate></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'limit-reports.fields.RUNTIME' | transloco }}</mat-label>
            <input matInput formControlName="runtime" readonly />
          </mat-form-field>
        </div>

        @if (
          formGroup.controls.costingName.value &&
          formGroup.controls.contractName.value
          ) {
          <div
            formContainerFormRow
            >
            <mat-form-field>
              <mat-label>{{
                'limit-reports.fields.COSTING_NAME' | transloco
              }}</mat-label>
              <input matInput placeholder="" formControlName="costingName" readonly />
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{
                'limit-reports.fields.CONTRACT_NAME' | transloco
              }}</mat-label>
              <input matInput formControlName="contractName" readonly />
            </mat-form-field>
          </div>
        }

        <div formContainerFormRow>
          <mat-form-field>
            <mat-label>{{
              'limit-reports.fields.BASE_FACTOR' | transloco
            }}</mat-label>
            <input matInput formControlName="baseFactor" type="number" readonly />
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{
              'limit-reports.fields.PEAK_FACTOR' | transloco
            }}</mat-label>
            <input matInput formControlName="peakFactor" type="number" readonly />
          </mat-form-field>
        </div>

        <div formContainerFormRow>
          <mat-form-field>
            <mat-label>{{
              'limit-reports.fields.SURCHARGE' | transloco
            }}</mat-label>
            <input
              matInput
              placeholder=""
              formControlName="surcharge"
              type="number"
              readonly
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{
                'limit-reports.fields.COMPOSITE_DEMAND' | transloco
              }}</mat-label>
              <input
                matInput
                formControlName="compositeDemand"
                type="number"
                readonly
                />
              </mat-form-field>
            </div>

            <mat-divider></mat-divider>
            <br />

            <div formContainerFormRow>
              <mat-form-field>
                <mat-label>{{
                  'limit-reports.fields.CREATED_BY' | transloco
                }}</mat-label>
                <input matInput formControlName="createdBy" readonly />
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{
                  'limit-reports.fields.CREATED_DATE' | transloco
                }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="pickerCreatedDate"
                  formControlName="createdDate"
                  readonly
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickerCreatedDate"
                    style="visibility: hidden"
                    >
                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerCreatedDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div formContainerFormRow>
                <mat-form-field>
                  <mat-label>{{
                    'limit-reports.fields.UPDATED_BY' | transloco
                  }}</mat-label>
                  <input matInput placeholder="" formControlName="updatedBy" readonly />
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{
                    'limit-reports.fields.UPDATED_DATE' | transloco
                  }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="pickerUpdatedDate"
                    formControlName="updatedDate"
                    readonly
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickerUpdatedDate"
                      style="visibility: hidden"
                      >
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerUpdatedDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <mat-divider></mat-divider>

                <div formContainerFormRow>
                  <mat-checkbox
                    class="w-full"
                    formControlName="showPositions"
                    [disableRipple]="true"
                    (click)="$event.preventDefault()"
                    >{{
                    'limit-reports.common-setting.fields.SHOW_POSITIONS' | transloco
                    }}</mat-checkbox
                    >

                    <mat-checkbox
                      class="w-full"
                      formControlName="showFormulaIndex"
                      [disableRipple]="true"
                      (click)="$event.preventDefault()"
                      >{{
                      'limit-reports.common-setting.fields.SHOW_FORMULA_INDEX' | transloco
                      }}</mat-checkbox
                      >
                    </div>

                    <div formContainerFormRow>
                      <mat-checkbox
                        class="w-full"
                        formControlName="showIndex"
                        [disableRipple]="true"
                        (click)="$event.preventDefault()"
                        >{{
                        'limit-reports.common-setting.fields.SHOW_INDEX' | transloco
                        }}</mat-checkbox
                        >

                        <mat-checkbox
                          class="w-full"
                          formControlName="showBenchmark"
                          [disableRipple]="true"
                          (click)="$event.preventDefault()"
                          >{{
                          'limit-reports.common-setting.fields.SHOW_BENCHMARK' | transloco
                          }}</mat-checkbox
                          >
                        </div>

                        <div formContainerFormRow>
                          <mat-checkbox
                            class="w-full"
                            formControlName="showPurchase"
                            [disableRipple]="true"
                            (click)="$event.preventDefault()"
                            >{{
                            'limit-reports.common-setting.fields.SHOW_PURCHASES_IN_CHART'
                            | transloco
                            }}</mat-checkbox
                            >

                            <mat-checkbox
                              class="w-full"
                              formControlName="showPassiveStrategy"
                              [disableRipple]="true"
                              (click)="$event.preventDefault()"
                              >{{
                              'limit-reports.common-setting.fields.SHOW_PASSIVE_STRATEGY'
                              | transloco
                              }}</mat-checkbox
                              >
                            </div>

                            <div formContainerFormRow>
                              <mat-checkbox
                                class="w-full"
                                formControlName="showTecIndOverallStrategy"
                                [disableRipple]="true"
                                (click)="$event.preventDefault()"
                                >{{
                                'limit-reports.tech-indicators.fields.GLOBAL_SHOW_TEC_IND_OVERALL_STRATEGY'
                                | transloco
                                }}</mat-checkbox
                                >

                                <mat-checkbox
                                  class="w-full"
                                  formControlName="showTecIndSideWay"
                                  [disableRipple]="true"
                                  (click)="$event.preventDefault()"
                                  >{{
                                  'limit-reports.tech-indicators.fields.GLOBAL_SHOW_TEC_IND_SIDE_WAY'
                                  | transloco
                                  }}</mat-checkbox
                                  >
                                </div>

                                <div formContainerFormRow>
                                  <mat-checkbox
                                    class="w-full"
                                    formControlName="showTecIndTrend"
                                    [disableRipple]="true"
                                    (click)="$event.preventDefault()"
                                    >{{
                                    'limit-reports.tech-indicators.fields.GLOBAL_SHOW_TEC_IND_TREND'
                                    | transloco
                                    }}</mat-checkbox
                                    >

                                    <mat-checkbox
                                      class="w-full"
                                      formControlName="showDynamicLimit"
                                      [disableRipple]="true"
                                      (click)="$event.preventDefault()"
                                      >{{
                                      'limit-reports.common-setting.fields.SHOW_DYNAMIC_LIMIT' | transloco
                                      }}</mat-checkbox
                                      >
                                    </div>

                                    <div formContainerFormRow>
                                      <mat-checkbox
                                        class="w-full"
                                        formControlName="showDynamicValueAtRisk"
                                        [disableRipple]="true"
                                        (click)="$event.preventDefault()"
                                        >
                                        {{
                                        'limit-reports.common-setting.fields.SHOW_DYNAMIC_VAR' | transloco
                                        }}</mat-checkbox
                                        >

                                        <mat-checkbox
                                          class="w-full"
                                          formControlName="showEnergySourcePolicy"
                                          [disableRipple]="true"
                                          (click)="$event.preventDefault()"
                                          >
                                          {{
                                          'limit-reports.common-setting.fields.SHOW_ENERGY_SOURCING_POLICY'
                                          | transloco
                                          }}</mat-checkbox
                                          >
                                        </div>

                                        <div formContainerFormRow>
                                          <mat-checkbox
                                            class="w-full"
                                            formControlName="showSpreadPositions"
                                            [disableRipple]="true"
                                            (click)="$event.preventDefault()"
                                            >{{
                                            'limit-reports.common-setting.fields.SPREAD_SHOW_POSITIONS'
                                            | transloco
                                            }}</mat-checkbox
                                            >
                                            <mat-checkbox
                                              class="w-full"
                                              formControlName="showSpreadIndex"
                                              [disableRipple]="true"
                                              (click)="$event.preventDefault()"
                                              >{{
                                              'limit-reports.common-setting.fields.SPREAD_SHOW_INDEX' | transloco
                                              }}</mat-checkbox
                                              >
                                            </div>

                                            <div formContainerFormRow>
                                              <mat-checkbox
                                                class="w-full"
                                                formControlName="showSpreadBenchmark"
                                                [disableRipple]="true"
                                                (click)="$event.preventDefault()"
                                                >{{
                                                'limit-reports.common-setting.fields.SPREAD_SHOW_BENCHMARK'
                                                | transloco
                                                }}</mat-checkbox
                                                >

                                                <mat-checkbox
                                                  class="w-full"
                                                  formControlName="showHknPositions"
                                                  [disableRipple]="true"
                                                  (click)="$event.preventDefault()"
                                                  >{{
                                                  'limit-reports.common-setting.fields.HKN_SHOW_POSITIONS' | transloco
                                                  }}</mat-checkbox
                                                  >
                                                </div>

                                                <div formContainerFormRow>
                                                  <mat-checkbox
                                                    class="w-full"
                                                    formControlName="showHknIndex"
                                                    [disableRipple]="true"
                                                    (click)="$event.preventDefault()"
                                                    >{{
                                                    'limit-reports.common-setting.fields.HKN_SHOW_INDEX' | transloco
                                                    }}</mat-checkbox
                                                    >

                                                    <mat-checkbox
                                                      class="w-full"
                                                      formControlName="showHknBenchmark"
                                                      [disableRipple]="true"
                                                      (click)="$event.preventDefault()"
                                                      >{{
                                                      'limit-reports.common-setting.fields.HKN_SHOW_BENCHMARK' | transloco
                                                      }}</mat-checkbox
                                                      >
                                                    </div>

                                                    <div formContainerFormRow>
                                                      <mat-checkbox
                                                        class="w-full"
                                                        formControlName="showPassiveStrategySpread"
                                                        [disableRipple]="true"
                                                        (click)="$event.preventDefault()"
                                                        >{{
                                                        'limit-reports.common-setting.fields.SPREAD_SHOW_PASSIVE_STRATEGY'
                                                        | transloco
                                                        }}</mat-checkbox
                                                        >

                                                        <mat-checkbox
                                                          class="w-full"
                                                          formControlName="showDynamicLimitSpread"
                                                          [disableRipple]="true"
                                                          (click)="$event.preventDefault()"
                                                          >{{
                                                          'limit-reports.common-setting.fields.SPREAD_SHOW_DYNAMIC_LIMIT'
                                                          | transloco
                                                          }}</mat-checkbox
                                                          >
                                                        </div>

                                                        <div formContainerFormRow>
                                                          <mat-checkbox
                                                            class="w-full"
                                                            formControlName="showDynamicValueAtRiskSpread"
                                                            [disableRipple]="true"
                                                            (click)="$event.preventDefault()"
                                                            >{{
                                                            'limit-reports.common-setting.fields.SPREAD_SHOW_DYNAMIC_VALUE_AT_RISK'
                                                            | transloco
                                                            }}</mat-checkbox
                                                            >

                                                            <mat-checkbox
                                                              class="w-full"
                                                              formControlName="showPassiveStrategyHkn"
                                                              [disableRipple]="true"
                                                              (click)="$event.preventDefault()"
                                                              >{{
                                                              'limit-reports.common-setting.fields.HKN_SHOW_PASSIVE_STRATEGY'
                                                              | transloco
                                                              }}</mat-checkbox
                                                              >
                                                            </div>

                                                            <div formContainerFormRow>
                                                              <mat-checkbox
                                                                class="w-full"
                                                                formControlName="showDynamicLimitHkn"
                                                                [disableRipple]="true"
                                                                (click)="$event.preventDefault()"
                                                                >{{
                                                                'limit-reports.common-setting.fields.HKN_SHOW_DYNAMIC_LIMIT'
                                                                | transloco
                                                                }}</mat-checkbox
                                                                >

                                                                <mat-checkbox
                                                                  class="w-full"
                                                                  formControlName="showDynamicValueAtRiskHkn"
                                                                  [disableRipple]="true"
                                                                  (click)="$event.preventDefault()"
                                                                  >{{
                                                                  'limit-reports.common-setting.fields.HKN_SHOW_DYNAMIC_VALUE_AT_RISK'
                                                                  | transloco
                                                                  }}</mat-checkbox
                                                                  >
                                                                </div>
                                                              </form>
                                                            </cc-form-container>
