import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  OfferPowerContract,
  OfferPowerService,
} from '@backoffice/costing/util-api';
import { FilterParam, ID } from '@clean-code/shared/common';
import { debounceTime, filter, Observable, Subject, switchMap } from 'rxjs';

@Component({
  selector: 'backoffice-limit-report-power-contract-assignment-dialog',
  templateUrl: './limit-report-power-contract-assignment-dialog.component.html',
})
export class LimitReportPowerContractAssignmentDialogComponent
  implements OnInit
{
  public timeSeriesIdentifierId: string;
  public timeSeriesIdentifier: string;
  public companyId: string;
  public filterParam: FilterParam[];
  public contractId: ID;

  public input$ = new Subject<string | null>();
  public items$: Observable<OfferPowerContract[]> = null;

  public formGroup = new UntypedFormGroup({
    offerContractId: new UntypedFormControl(null),
  });

  constructor(
    public dialogRef: MatDialogRef<LimitReportPowerContractAssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    public offerPowerService: OfferPowerService,
  ) {
    this.timeSeriesIdentifierId = data.timeSeriesIdentifierId;
    this.timeSeriesIdentifier = data.timeSeriesIdentifier;
    this.companyId = data.companyId;
    this.filterParam = [
      {
        key: 'timeSeriesIdentifierId',
        value: data.timeSeriesIdentifierId.toString(),
      },
      {
        key: 'companyId',
        value: data.companyId.toString(),
      },
    ];
  }

  public ngOnInit(): void {
    this.items$ = this.input$.pipe(
      debounceTime(500),
      filter(() => !!this.timeSeriesIdentifierId),
      switchMap((q: string) => {
        return this.offerPowerService.searchContract$({
          searchTerm: q,
          filters: this.filterParam,
        });
      }),
    );
  }

  public onFocus() {
    this.input$.next('');
  }

  public changeSelectedContract(offerContract: OfferPowerContract) {
    this.contractId = offerContract.id;
  }
}
