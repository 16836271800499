import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LimitReportListPreviewBaseComponent } from '@backoffice-frontend/limit-report/domain';
import { ID } from '@clean-code/shared/common';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { takeUntil } from 'rxjs/operators';
import { LimitReportPowerService } from '../../services/limit-report.service';
import { LimitReportPowerContractAssignmentDialogComponent } from '../limit-report-power-contract-assignment-dialog/limit-report-power-contract-assignment-dialog.component';

@Component({
  templateUrl: './limit-report-list-preview.component.html',
})
export class LimitReportPowerListPreviewComponent extends LimitReportListPreviewBaseComponent {
  protected override navigateUrl = '/limit-reports/power/limit-report/';

  public formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null),
    customerName: new UntypedFormControl(null),
    customerId: new UntypedFormControl(null),
    costingName: new UntypedFormControl(null),
    contractName: new UntypedFormControl(null),

    defaultChartStartDate: new UntypedFormControl(null),
    runtime: new UntypedFormControl(null),

    timeSeriesIdentifier: new UntypedFormControl(null),
    timeSeriesIdentifierId: new UntypedFormControl(null),
    compositeDemand: new UntypedFormControl(null),

    baseFactor: new UntypedFormControl(1),
    peakFactor: new UntypedFormControl(0),
    surcharge: new UntypedFormControl(0),
    state: new UntypedFormControl('NEW'),

    updatedDate: new UntypedFormControl(null),
    updatedBy: new UntypedFormControl(null),
    createdDate: new UntypedFormControl(null),
    createdBy: new UntypedFormControl(null),

    showPositions: new UntypedFormControl(false),
    showFormulaIndex: new UntypedFormControl(false),
    showIndex: new UntypedFormControl(false),
    showBenchmark: new UntypedFormControl(false),

    showSpreadPositions: new UntypedFormControl(false),
    showSpreadIndex: new UntypedFormControl(false),
    showSpreadBenchmark: new UntypedFormControl(false),

    showHknPositions: new UntypedFormControl(false),
    showHknIndex: new UntypedFormControl(false),
    showHknBenchmark: new UntypedFormControl(false),

    showPurchase: new UntypedFormControl(false),
    showPassiveStrategy: new UntypedFormControl(false),
    showTecIndOverallStrategy: new UntypedFormControl(false),
    showTecIndSideWay: new UntypedFormControl(false),
    showTecIndTrend: new UntypedFormControl(false),
    showDynamicLimit: new UntypedFormControl(false),
    showDynamicValueAtRisk: new UntypedFormControl(false),
    showEnergySourcePolicy: new UntypedFormControl(false),

    showPassiveStrategySpread: new UntypedFormControl(false),
    showDynamicLimitSpread: new UntypedFormControl(false),
    showDynamicValueAtRiskSpread: new UntypedFormControl(false),

    showPassiveStrategyHkn: new UntypedFormControl(false),
    showDynamicLimitHkn: new UntypedFormControl(false),
    showDynamicValueAtRiskHkn: new UntypedFormControl(false),
  });

  constructor(
    activatedRoute: ActivatedRoute,
    protected override service: LimitReportPowerService,
    router: Router,
    protected confirmService: ConfirmService,
  ) {
    super(activatedRoute, service, router);
  }

  public assignToContract(): void {
    this.confirmService
      .open(
        '',
        '',
        LimitReportPowerContractAssignmentDialogComponent,
        {
          timeSeriesIdentifier:
            this.formGroup.controls.timeSeriesIdentifier.value,
          timeSeriesIdentifierId:
            this.formGroup.controls.timeSeriesIdentifierId.value,
          companyId: this.formGroup.controls.customerId.value,
        },
        { width: '800px' },
      )
      .pipe(takeUntil(this.closeSubject))
      .subscribe((contractId: ID) => {
        if (contractId) {
          this.service
            .assignToContract$(this.id, contractId)
            .pipe(/*indicate(this.isLoading$)*/)
            .subscribe(() => {
              this.overview();
            });
        }
      });
  }
}
