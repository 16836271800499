import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@jsverse/transloco';
import { LimitReportPowerContractAssignmentDialogComponent } from './limit-report-power-contract-assignment-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    TranslocoModule,
    NgSelectModule,
    FormContainerModule,
  ],
  providers: [ConfirmService],
  declarations: [LimitReportPowerContractAssignmentDialogComponent],
  exports: [LimitReportPowerContractAssignmentDialogComponent],
})
export class LimitReportPowerContractAssignmentDialogModule {}
